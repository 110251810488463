import { Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { UserForm } from 'components/forms';
import { Td, TdActions, TdBin } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers, FormikProps } from 'formik';
import { getEmail, getId, getResults, isResultsEmpty } from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { MdEmail } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { MiscResources } from 'services/resources/misc';
import { UserResources } from 'services/resources/users/user';
import {
  IUserPayload,
  IUserResource,
} from 'services/resources/users/user/types.d';

import {
  getAccreditationDate,
  getCreated,
  getIsAccredited,
  getIsStaff,
  getIsSuperuser,
  getLastLogin,
  getPhone,
  getPrivacy,
  getTerms,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultRestore,
    doFetch,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    params,
    registry,
    state,
    toggleDrawer,
  } = useGenericContext<IUserResource>();

  const dispatch = useDispatch();

  const ref = useRef<FormikProps<IUserPayload>>(null);

  const prepareFormValues = useCallback(
    fp.omit([
      'industries',
      'differentiations',
      'company_stages',
      'company_rounds',
      'beneficiaries',
      'references',
    ]),
    [],
  );

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const defaultPatch = async (
    { marco_contract: c, ...v }: IUserPayload,
    h?: FormikHelpers<IUserPayload>,
  ) => {
    const p = new FormData();
    if (
      typeof v?.kyc_physical_person === 'object' &&
      v?.kyc_physical_person !== null &&
      v?.kyc_physical_person?.kyc_validation !== null &&
      v?.kyc_physical_person?.kyc_validation === false
    ) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      p.append('kyc_update_date', formattedDate);
    } else {
      console.log('The kyc_physical_person is not an object');
    }

    if (!fp.isString(c) && !fp.isNil(c))
      p.append('marco_contract', c as File, (c as File).name);

    fp.compose(
      fp.each((k: string) => p.append(k, v[k])),
      fp.keys,
      fp.omitBy(fp.isNil),
      fp.pick([
        'accreditation_date',
        'email',
        'is_accredited',
        'is_confirmated_account',
        'is_senior_investor',
        'privacy',
        'marco_contract',
        'terms',
        'is_active',
      ]),
    )(v);

    try {
      if (fp.compose(fp.isNil, getId)(v)) {
        await UserResources.create(p as IUserPayload);
      } else {
        await UserResources.update(getId(v), p as IUserPayload);
      }

      await doFetch(params);

      toggleDrawer();

      if (!fp.isString(c) && !fp.isNil(c)) {
        dispatch(addSuccessToast('toast.success.files'));
      } else {
        dispatch(addSuccessToast('toast.success.network'));
      }
    } catch (err) {
      dispatch(addErrorToast('toast.errors.files.network'));
    } finally {
      h?.setSubmitting(false);
    }
  };

  const doEmail = useCallback(async (email: string) => {
    try {
      await MiscResources.mailing({
        event: 'USER_IS_ACCREDITED',
        email,
        username: '',
      });
      dispatch(addSuccessToast('toast.success.accreditation-email'));
    } catch (e) {
      dispatch(addErrorToast('toast.errors.accreditation-email'));
    }
  }, []);

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IUserResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getEmail(row)}</Td>
            <Td>{getPhone(row)}</Td>
            <TdBin isCheck={getIsSuperuser(row)} />
            <TdBin isCheck={getIsStaff(row)} />
            <TdBin isCheck={getIsAccredited(row)} />
            <TdBin isCheck={getTerms(row)} />
            <TdBin isCheck={getPrivacy(row)} />
            <Td>{getAccreditationDate(row)}</Td>
            <Td>{getLastLogin(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdActions
              customButtonBg="orange"
              customButtonIcon={<Icon as={MdEmail} color="white" />}
              isActive={active}
              onCustomCallback={() => doEmail(getEmail(row))}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <UserForm
          initialValues={prepareFormValues(registry)}
          onSubmit={defaultPatch}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
